import Cards_Data from '../../Data/Cards_Data'
import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

function Card() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return (
        <div className='w-full py-8'>
            <div className='w-full sectionlayout lato'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-8 gap-5 md:gap-8 lg:gap-24'>
                    {Cards_Data.map((card, index) => (
                        <div key={index} className='px-4 md:px-8'  data-aos='fade-up' >
                            <div className='flex items-center justify-center pt-2'>
                                <img src={card.img} alt={card.alt} className='h-[150px] md:h-[220px]'   />
                            </div>
                            <h2 style={{ fontSize: 'clamp(20px,2vw,32px)' }} className='font-extrabold text-[#4C4845] text-center mt-5 mb-2'  >{card.title}</h2>
                            <p style={{ fontSize: 'clamp(20px,1.5vw,24px)' }} className='font-medium text-[#4A5568] text-center pb-8'  >{card.des}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Card