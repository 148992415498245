import './Navbar.css'
import React from 'react'
import Mobile from './Mobile'
import Desktop from './Desktop'

function Navbar() {
  return (
    <>
    <Mobile/>
    <Desktop/>
    </>
  )
}

export default Navbar
