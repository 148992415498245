import './Navbar.css';
import React, { useState} from "react";
import Dynamic_Leo from '../../Assets/Dynamic_Leo.png';
import { TfiClose } from "react-icons/tfi";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Social from '../Social/Social';
import Navbar from '../../Data/Navbar';

function Mobile() {
    const [nav, setNav] = useState(false);

    const handleCrossNav = () => {
        setNav(false);
    };
    const handleOpenNav = () => {
        setNav(true);
    };

    const handleLinkClick = (path) => {
        handleNav(path);
        setNav(false);
    };

    const handleNav = (path) => {
        const section = document.querySelector(path);
        if (section) {
            const offset = window.innerHeight * 0.12;
            const sectionTop = section.offsetTop - offset;

            window.scrollTo({
                top: sectionTop 
            });
        }
    };

    return (
        <>
            <div className="lg:hidden sticky top-0 bg-white h-24 flex items-center w-full z-[10000]">
                <div className="sectionlayout poppins text-[#6C6C6C] h-[10vh] flex justify-between items-center w-full">
                    <div className='flex items-center justify-center'>
                        <NavLink to="/" onClick={() => handleLinkClick("#home")}>
                            <img src={Dynamic_Leo} className='max-h-[9vh] max-w-[80%]' alt="dynamic leo" />
                        </NavLink>
                    </div>
                    <div className="flex items-center">
                        <button
                            className="inline-flex items-center rounded-md hover:text-[#99702B] ease-in-out transition-all duration-200 py-2 px-4 p-2 text-lg font-medium"
                            onClick={handleOpenNav}
                        >
                            <FaBars
                                size={20}
                                className="text-[#454545] drop-shadow-md cursor-pointer hover:text-[#99702B] ease-in-out transition-all duration-200"
                            />
                        </button>
                    </div>
                    <div className={`navbar-menu ${nav ? 'open' : ''}`}>
                        <div className="sticky bg-white shadow-xl border-l border-white/70 overflow-y-auto">
                            <ul className="pt-4 px-6 pb-3 overflow-y-auto h-[100vh]">
                                <div className="flex justify-end pt-4">
                                    <TfiClose
                                        onClick={handleCrossNav}
                                        className="rounded p-1 font-bold hover:bg-black/5"
                                        size={24}
                                    />
                                </div>
                                <div className="mt-6">
                                    {Navbar.links.map((link, index) => (
                                        <React.Fragment key={index}>
                                            <NavLink
                                                to={link.path}
                                                className={({ isActive }) =>
                                                    `cursor-pointer px-1 flex items-center justify-between Georgian py-3 pl-3 font-medium hover:rounded hover:bg-black/5 ${isActive ? 'text-[#B19361]' : 'text-[#6C6C6C]'}`
                                                }
                                                onClick={() => handleLinkClick(link.path)}
                                            >
                                                {link.name}
                                            </NavLink>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className='w-56 z-10 absolute bottom-10'>
                                    <div className='flex items-center justify-center flex-col'>
                                        <div className='flex justify-center items-center mb-5'>
                                            <img src={Dynamic_Leo} alt="dynamic leo" className='min-w-[70%] max-h-[20vh] pl-4' />
                                        </div>
                                        <div>
                                            <Social />
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mobile;
