import homehero from '../../Assets/Hero/home_hero.png'
import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div
            className="w-full bg-white overflow-hidden" id='home'
        >
            <div className="mx-auto max-w-[1640px] lato z-10 relative py-[10rem] h-full flex items-center justify-end"
                style={{
                    backgroundImage: `url(${homehero})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: 'auto',
                }}>
                <div className='w-[80%] md:w-[40%] text-right pr-[1rem] md:pr-[2rem]'>
                    <h1
                        style={{ fontSize: 'clamp(30px,4.5vw,70px)' }}
                        className='text-white uppercase font-extrabold' data-aos='fade-up-left'>
                        Empowering your digital future
                    </h1>
                </div>
            </div>
        </div>
    )
}
export default Hero
