import React, { useEffect } from 'react';

function Redirect({ link }) { // Destructure the link prop
  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  return (
    <div className='w-full sectionlayout'>Loading...</div>
  );
}

export default Redirect;