import ECommerce from '../Assets/our_services/E-Commerce.png';
import DropShipping from '../Assets/our_services/Drop-Shipping.png';
import ItSolutions from '../Assets/our_services/It-Solutions.png';

const Products = [
    {
        bgcolor: "linear-gradient(135deg, #FEB692 0%, #EA5455 100%)",   
        img: ECommerce,
        alt:"E-Commerce",
        title: "E-commerce",
        link: "https://dynamicleoecommerce.com",
    },
   
    {
        bgcolor: "linear-gradient(135deg, #97ABFF 0%, #123597 100%)",
        img: ItSolutions,
        title: "IT-Solutions",
        alt:"It-Solutions",
        link: "https://dynamicleoitsolutions.com",
    },
    {
        bgcolor: "linear-gradient(135deg, #FFE985 0%, #D0B571 100%)",
        img: DropShipping,
        title: "Drop-shipping",
        alt:"Drop-Shipping",
        link: "/",
    }
];

export default Products;
