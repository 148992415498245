import './Navbar.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import Dynamic_Leo from '../../Assets/Dynamic_Leo.png';
import dropdown from '../../Assets/icons/dropdown.svg';
import Navbar from '../../Data/Navbar';

function Desktop() {
  const [activeSection, setActiveSection] = useState('');

  const handleNav = (path) => {
    const section = document.querySelector(path);
    if (section) {
      const offset = window.innerHeight * 0.12;
      const sectionTop = section.offsetTop - offset;

      window.scrollTo({
        top: sectionTop,
        
      });
    }
  };

  const handleScroll = () => {
    Navbar.links.forEach(link => {
      const section = document.querySelector(link.path);

      if (section) {
        const sectionTop = section.offsetTop - (window.innerHeight * 0.12);
        const sectionHeight = section.offsetHeight;

        if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
          setActiveSection(link.path);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="w-full bg-white hidden lg:block sticky top-0 z-20">
      <div className="sectionlayout flex items-center justify-between poppins text-[#6C6C6C] h-[14vh] border-b">
        <div>
          <img className="max-h-[60px] max-w-[236px]" src={Dynamic_Leo} alt="Dynamic Leo" />
        </div>
        <div>
          <div className="flex items-center justify-center hover:cursor-pointer">
            {Navbar.links.map((link, index) =>
              !link.submenu ? (
                <div key={index}>
                  <a
                    href={link.path}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNav(link.path);
                    }}
                    className={`px-5 py-2 rounded-md hover:bg-gray-200 ${activeSection === link.path ? 'font-bold' : 'text-gray-500'}`}
                  >
                    {link.name}
                  </a>
                </div>
              ) : (
                <div key={index} className="relative group flex dropown_menu_trigger">
                  <NavLink
                    onClick={handleNav}
                    to={link.path}
                    className={({ isActive }) =>
                      `px-5 py-2 rounded-md hover:bg-gray-200 flex ${isActive ? 'font-bold' : 'text-gray-500'
                      }`
                    }
                  >
                    {link.name}
                    <div className="flex">
                      <img className="pl-1" src={dropdown} alt="dropdown" />
                    </div>
                  </NavLink>
                  <div className="absolute mt-10 dropown_menu z-[100]">
                    <div className="bg-gray-50 font-normal rounded-md w-[15rem] mt-2">
                      {link.submenu.map((sublink, subIndex) => (
                        <div
                          key={subIndex}
                          className="pl-4 pr-8 py-2 hover:bg-gray-200"
                        >
                          <NavLink
                            onClick={handleNav}
                            to={sublink.path}
                            className={({ isActive }) =>
                              isActive ? 'font-bold' : 'text-gray-500'
                            }
                          >
                            {sublink.name}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <Link to="contact-us" offset={-50}>
            <button className="px-[17px] py-[7px] border-[2px] border-[#6c6c6c] rounded-[10px] font-semibold">
              Let's Talk
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Desktop;
