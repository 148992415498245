import line from '../../Assets/icons/line.svg'
import check_out from '../../Assets/our_services/Check_Out.svg'
import Products from '../../Data/Products'
import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
function WhatWeOffer() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return (
        <div className="w-full bg-white" id='what-we-offer'>
            <div className="sectionlayout lato relative pt-[6rem]">
                <div className='absolute right-[1rem] md:right-[2rem] -top-[5rem]'  data-aos='fade-up' >
                    <img src={check_out} alt="Check_Out" className='h-[12rem] md:h-[15rem]' />
                </div>
                <div className='text-center p-4 md:p-8'>
                    <div className='flex items-center justify-center pt-[6rem] md:pt-[3rem]'>
                        <img src={line} alt="" className="h-1 w-full max-w-[100px] md:max-w-[150px]"  data-aos='fade-down' data-aos-delay="200"/>
                        <h1 style={{ fontSize: 'clamp(17px,1.2vw,20px)' }} className='uppercase text-[#463820] opacity-[63%] font-semibold w-[20rem]'  data-aos='fade-up' >What We Offer</h1>
                        <img src={line} alt="" className="h-1 w-full max-w-[100px] md:max-w-[150px]"  data-aos='fade-down' data-aos-delay="200" />
                    </div>
                    <div className='mt-4'>
                        <h1 style={{ fontSize: 'clamp(24px,4vw,46px)' }} className='uppercase text-[#C9AA5D] w-[90%] md:w-[80%] mx-auto font-extrabold'  data-aos='fade-up' data-aos-delay="200">OUR SERVICES</h1>
                    </div>
                    <div className='mt-4' data-aos='fade-up' >
                        <p style={{ fontSize: 'clamp(16px,2.5vw,28px)' }} className='text-[#726652] w-[90%] md:w-[80%] mx-auto font-medium leading-[1.5] md:leading-[1.75]'  >
                        We offer a range of advanced IT and software solutions to realize your digital ambitions. From cutting-edge innovations to dependable, scalable systems, our expertise and technology help bring your vision to life with unparalleled quality.
                        </p>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 gap-5 md:gap-8 mb-16' data-aos='fade-up'>
                    {Products.map((card, index) => (
                        <a href={card.link} target="_blank" rel="noopener noreferrer"   >
                            <div key={index} className={`px-4 md:px-8 py-16 rounded-xl drop-shadow-lg  cursor-pointer`} style={{background:`${card.bgcolor}`}} >
                                <div className='flex items-center justify-center pt-2'>
                                <img src={card.img} alt={card.alt} className='h-[150px] md:h-[220px]' loading="lazy" />
                                </div>
                                <h1 style={{ fontSize: 'clamp(20px,2vw,32px)' }} className='font-extrabold text-white text-center mt-5 mb-2'>{card.title}</h1>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhatWeOffer
