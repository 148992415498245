import background from '../../Assets/Hero/home_empowering.jpg'
import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
function Empowering() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return (
        <div
            className="w-full bg-white"
        >
            <div className="mx-auto max-w-[1640px] lato z-10 relative py-[10rem] md:py-[15rem] h-full flex items-center justify-center"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: 'auto',
                }}>
                <div className='p-[1rem] md:p-[2rem]'>
                    <h1
                        style={{ fontSize: 'clamp(30px,4.5vw,70px)' }}
                        className='text-white uppercase font-extrabold text-center' data-aos='fade-up' >
                        Digital Dreams, Tangible Results
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Empowering
