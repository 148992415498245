import InnovativeSolutions from '../Assets/Cards/Innovative-Solutions.svg';
import ComprehensiveServices from '../Assets/Cards/Comprehensive-Services.svg';
import ExpertTeam from '../Assets/Cards/Expert-Team.svg';
import ClientCentricApproach from '../Assets/Cards/Client-Centric-Approach.svg';

const Cards_Data = [
    {
        img: InnovativeSolutions,
        alt:"Innovative-Solutions",
        title: "Innovative Solutions",
        des: "We craft tailored IT and e-commerce solutions that propel your business forward in the tech market, ensuring you stay ahead in the digital landscape."
    },
    {
        img: ComprehensiveServices,
        alt:"Comprehensive-Services",
        title: "Comprehensive Services",
        des: "From web development to software integration, our services cover all aspects of your digital transformation, giving you the products to excel."
    },
    {
        img: ExpertTeam,
        alt:"Expert-Team",
        title: "Expert Team",
        des: "Our expert team delivers innovative IT and e-commerce solutions to keep your business ahead in the digital world."
    },
    {
        img: ClientCentricApproach,
        alt:"Client-Centric-Approach",
        title: "Client-Centric Approach",
        des: "We focus on your needs, crafting IT and e-commerce solutions that are perfectly aligned with your business goals."
    },
];

export default Cards_Data;
