import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import Redirect from './Pages/Redirect/Redirect'

function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/freeshopifyseminar" element={<Redirect link="https://www.facebook.com/share/p/CbH6cGR3VbmnSCvJ/" />} />
        <Route exact path="/classshopify" element={<Redirect link="https://dynamicleoecommerce.com/" />} />
      </Routes>
      <Footer/>
    </>
  );
}
export default App;

